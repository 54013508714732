// index.js v.0.0.69.003
//
// CHANGELOG from v.0.0.69.002 to v.0.0.69.003
// 1. Added version display at the bottom of the LandingPage component via props.

import Script from 'next/script';
import Head from 'next/head';
import LandingPage from '../components/LandingPage';

const indexVerNum = "index.js v.0.0.69.003";

export default function Home() {
    const versionParts = indexVerNum.split('.');
    const versionDisplay = versionParts.length > 0 ? versionParts[versionParts.length - 1] : 'unknown';

    return (
        <>
            <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-692819199"
                strategy="afterInteractive"
            />
            <Script id="gtag-common" strategy="afterInteractive">
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-692819199');
        `}
            </Script>

            <Head>
                <title>HEAT Freight</title>
            </Head>
            <LandingPage versionDisplay={versionDisplay} />
        </>
    );
}